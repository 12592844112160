/**
 * Site header
 */

header {
    position: fixed;
    top: 0;
    left: 0;

    display: table;
    //table-layout: auto;
    //@include media-query($on-palm) {
    //    table-layout: fixed;
    //}
    box-sizing: border-box;
    width: 100%;

    background-color: black;
    color: white;
    -webkit-box-shadow: 0em 0.75em 1.5em 0.75em rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0em 0.75em 1.5em 0.75em rgba(0, 0, 0, 0.6);
    box-shadow: 0em 0.75em 1.5em 0.75em rgba(0, 0, 0, 0.6);

    h2 {
        padding-left: $base-indentation;
        //padding: 0 $base-indentation;
        //width: auto;
        @include media-query($on-palm) {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    * {
        display: table-cell;
        vertical-align: middle;
        //height: 100%;
        padding: 0;
        margin: 0;
        //border: 2px solid red;
    }

    a {
        float: right;
        color: white;

        line-height: $header-height;
        text-decoration: none;

        align-items: center;
        padding: 0 $base-indentation;

        transition: background-color 0.5s ease;

        &:hover {
            background-color: $link-color;
            text-decoration: none;
            color: white;
        }

        &:visited {
            color: white;
        }
    }

    input, label {
        display: none;
    }

    ul, li {
        display: inline;
    }

    @include media-query($on-palm) {

        position: absolute;

        a {
            float: initial;
            line-height: 2em;
        }

        label {
            display: block;
            padding: 0 0.5em;
            line-height: 2em;
            cursor: pointer;
            position: fixed;
            bottom: 0.5em;
            right: 0.5em;
            background-color: black;
            //-webkit-box-shadow: 0.5em 0.5em 1.5em 0.75em rgba(0, 0, 0, 0.6);
            //-moz-box-shadow: 0.5em 0.5em 1.5em 0.75em rgba(0, 0, 0, 0.6);
            //box-shadow: 0.2em 0.2em 0.5em 0.1em rgba(0, 0, 0, 0.6);
            border-radius: 5px;
            //text-align: right;
            font-size: x-large;
            z-index: 5;
        }

        ul {
            display: block;
            position: fixed;
            bottom: 0.5em;
            right: 0.5em;
            background-color: black;
            //-webkit-box-shadow: 0.5em 0.5em 1.5em 0.75em rgba(0, 0, 0, 0.6);
            //-moz-box-shadow: 0.5em 0.5em 1.5em 0.75em rgba(0, 0, 0, 0.6);
            //box-shadow: 0.2em 0.2em 0.5em 0.1em rgba(0, 0, 0, 0.6);
            border-radius: 5px;
            //text-align: right;
            font-size: x-large;
        }

        li {
            line-height: 2em;
            display: block;
        }

        input ~ nav > ul {
            //float: none;
            //clear: both;
            //display: none;
            padding: 0;
            visibility: hidden;
            transform: scale(0);
            transform-origin: 100% 100%;
            transition: transform 0.3s, visibility 0s 0.3s;
        }

        input:checked ~ nav > ul {
            //display: block;
            //margin-right: 0;
            //line-height: normal;
            visibility: visible;
            transform: scale(1);
            transition: transform 0.3s, visibility 0s 0s
        }

    }
}

.headerplus {
    padding-right: $base-indentation;
    vertical-align: middle;
    white-space: nowrap;

    & > * {
        //float: right;
        padding: 0;
        margin: 0;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

