/**
 * Reset some basic elements
 */
body, figure
//, h1, h2, h3, h4, h5, h6,
//p, blockquote, pre, hr,
//dl, dd, ol, figure
{
    margin: 0;
    padding: 0;
}

//ul {
//    padding: 0;
//}

.main, footer{
    padding-left: $base-indentation;
    padding-right: $base-indentation;
}

.main {
    background-color: $background-color;
}

footer {
    //border-top: 1px solid $grey-color-light;
    color: $background-color;
    text-align: center;
    padding: 1em 0;

    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(top, #000000 0%, $background 75%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #000000 0%, $background 75%);
    /* Opera */
    background-image: -o-linear-gradient(top, #000000 0%, $background 75%);
    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000000), color-stop(0.75, $background));
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #000000 0%, $background 75%);
    /* W3C Markup, IE10 Release Preview */
    background-image: linear-gradient(to bottom, #000000 0%, $background 75%);
}

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    //font-weight: 300;
    color: $text-color;
    background-color: $background;
    -webkit-text-size-adjust: 100%;
    text-align: justify;
}

#main {
    background-color: $background-color;
    padding-top: $header-height;
}

article {
    padding-top: 1em;
    padding-bottom: 1em;
}

hr {
    margin: 0 $base-indentation;
    border-top: 0;
    border-bottom: 1px solid $brand-color;
}

//@include media-query($on-palm) {
//    body {
//        font-size: $base-font-size;
//    }
//}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
//h1, h2, h3, h4, h5, h6,
//p, blockquote, pre, ol, ul, dl, figure,
//%vertical-rhythm {
//    margin-bottom: $spacing-unit / 2;
//}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
//ul, ol {
//    margin-left: $spacing-unit;
//}

//
//li {
//    > ul,
//    > ol {
//         margin-bottom: 0;
//    }
//}



/**
 * Headings
 */
//h1, h2, h3, h4, h5, h6 {
//    font-weight: 300;
//}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $link-color;
        //text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    //font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

code {
    //padding: 1px 5px;
    border: 0;
}

pre {
    padding: 1rem;
    overflow: auto;
//
//    > code {
//        border: 0;
//        padding-right: 0;
//        padding-left: 0;
//    }
}



/**
 * Wrapper
 */
//.wrapper {
//    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
//    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
//    margin-right: auto;
//    margin-left: auto;
//    padding-right: $spacing-unit;
//    padding-left: $spacing-unit;
//    @extend %clearfix;
//
//    @include media-query($on-laptop) {
//        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
//        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
//        padding-right: $spacing-unit / 2;
//        padding-left: $spacing-unit / 2;
//    }
//}



/**
 * Clearfix
 */
//%clearfix {
//
//    &:after {
//        content: "";
//        display: table;
//        clear: both;
//    }
//}



/**
 * Icons
 */
//.icon {
//
//    > svg {
//        display: inline-block;
//        width: 16px;
//        height: 16px;
//        vertical-align: middle;
//
//        path {
//            fill: $grey-color;
//        }
//    }
//}
